<template>
  <div id="currentOrderAttendanceFAB" :class="'rounded ' + (showBody ? 'coaFAB-opened' : 'coaFAB-closed')">
    <div class="card-header cursor-pointer currentOrderAttendanceFABHeader pb-4 pt-2 px-0"
      @click="toggleShowBody">
      <i :class="'fa fa-lg ' + (showBody ? 'fa-caret-down' : 'fa-caret-up') + ' mx-3 float-right'" style="color:#eee;"></i>
    </div>
    <div v-if="showBody" class="card-body p-0">
      <div v-if="loading" class="mr-5 ml-5 pr-5 mt-5 mb-n4">
        <loading-animation />
      </div>

      <div v-else>
        <div v-for="(orderAttendance, index) in currentOrderAttendances" :key="index" :class="{ 'bg-light': index % 2 == 0 }"
          class="row py-2 mx-0 border cursor-pointer justify-content-center" v-b-tooltip.hover.left="'Clique para finalizar o apontamento'"
          @click="openStopAttendanceConfirmationModal(index)">
          <div class="col-auto">
            <span class="font-weight-bold">Pedido: </span><br>
            {{orderAttendance.order ? orderAttendance.order.number : orderAttendance.stockAdjustment ? orderAttendance.stockAdjustment.number : ''}}
            <span v-if="orderAttendance.showType" class="badge" :class="operationBadgeClassObject(orderAttendance)">
              {{orderAttendance.showType}}
            </span>
          </div>
          <div class="col-auto">
            <span class="font-weight-bold">Início: </span><br> {{orderAttendance.formattedStartDate}}
          </div>
          <div class="col-auto">
            <span class="font-weight-bold">Tempo: </span><br> {{orderAttendance.hoursAndMinutes}}
          </div>
        </div>

        <div v-if="!isAnyOfTheCurrentOrderAttendancesFromThisOrder" class="row justify-content-center">
          <div class="col-auto">
            <button type="button" class="btn btn-lg btn-success my-2 mx-3" @click="startAttendance">
              <i class="fa fa-play"></i> Iniciar Apontamento
            </button>
          </div>
        </div>
      </div>
    </div>

    <confirmation-modal
      id="stop-attendance-confirmation-modal"
      msg="Tem certeza de que deseja finalizar esse apontamento?"
      :ok="stopAttendance"
    ></confirmation-modal>
  </div>
</template>

<script>
  import { LoadingAnimation } from '@/components/loaders';
  import shared from '@/shared/shared';
  import { httpClient } from '@/service';
  import ConfirmationModal from '@/components/common/confirmation-modal';

  export default {
    name: 'order-attendance-fab',
    components: { LoadingAnimation, ConfirmationModal },

    props: {
      orderId: {
        type: String
      },
      stockAdjustmentId: {
        type: String
      },
      type: {
        type: String
      },
    },

    data() {
      return {
        user: null,
        showBody: true,
        loading: false,
        currentOrderAttendances: [],
        isAnyOfTheCurrentOrderAttendancesFromThisOrder: false,
        stopIndex: null
      }
    },

    mounted() {
      this.user = shared.getLocalStorageObj('auth/user');
      this.showBody = Boolean(shared.getLocalStorageString('order-attendance/showBody'));
      this.findCurrentOrderAttendances();

      setInterval(() => {
        this.updateEndDate();
      }, 60000);
    },

    methods: {
      updateEndDate() {
        if (this.currentOrderAttendances) {
          let endDate = new Date();

          this.currentOrderAttendances.forEach(oa => {
            oa = shared.setOrderAttendanceHoursAndMinutes(oa, endDate, false);
          });
        }
      },

      toggleShowBody() {
        this.showBody = !this.showBody;
        shared.setLocalStorageString('order-attendance/showBody', this.showBody);
      },

      findCurrentOrderAttendances() {
        this.loading = true;
        this.currentOrderAttendances = [];
        httpClient.post(`${process.env.VUE_APP_API_URL}order-attendances/condition?page=${0}&size=${10}`, {
          conditions: [
            {
              logicalOperator: 'OR',
              conditions: [
                {
                  field: 'automatic',
                  conditionalOperator: 'IS_NULL'
                },
                {
                  field: 'automatic',
                  value: false
                }
              ]
            },
            {
              field: 'user.id',
              value: this.user.id
            },
            {
              field: 'endDate',
              conditionalOperator: 'IS_NULL'
            },
            {
              logicalOperator: 'OR',
              conditions: [
                {
                  field: 'type',
                  conditionalOperator: 'IS_NULL'
                },
                {
                  field: 'type',
                  conditionalOperator: 'NOT_EQUALS',
                  value: 'CONF'
                }
              ]
            }
          ]
        }).then(data => {
          this.loading = false;
          this.currentOrderAttendances = data.data.data.content;
          let endDate = new Date();

          this.currentOrderAttendances.forEach(oa => {
            oa = shared.setAttendanceFields(oa, endDate, false);
          });

          this.updateIsAnyOfTheCurrentOrderAttendancesFromThisOrder();
        }).catch(error => {
          this.loading = false;
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError('Houve um erro ao buscar os apontamentos atuais')
          }
        });
      },

      updateIsAnyOfTheCurrentOrderAttendancesFromThisOrder() {
        this.isAnyOfTheCurrentOrderAttendancesFromThisOrder = this.currentOrderAttendances.find(oa => {
          return (
            this.orderId && oa.order && oa.order.id === this.orderId
          ) || (
            this.stockAdjustmentId && oa.stockAdjustment && oa.stockAdjustment.id === this.stockAdjustmentId
          )
        });
      },

      startAttendance() {
        let orderAttendance = {
          startDate: new Date(),
          user: this.user,
          type: this.type
        };

        if (this.orderId) {
          orderAttendance.order = { id: this.orderId };
        } else if (this.stockAdjustmentId) {
          orderAttendance.stockAdjustment = { id: this.stockAdjustmentId };
        }

        httpClient.post(`${process.env.VUE_APP_API_URL}order-attendances`, orderAttendance)
        .then(data => {
          this.loading = false;
          this.$notify.success('Apontamento iniciado com sucesso');
          orderAttendance = data.data.data;
          let endDate = new Date();
          orderAttendance = shared.setAttendanceFields(orderAttendance, endDate, false);
          this.currentOrderAttendances.push(orderAttendance);
          this.isAnyOfTheCurrentOrderAttendancesFromThisOrder = true;
        }).catch(error => {
          this.loading = false;
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError('Houve um erro ao iniciar o apontamento')
          }
        });
      },

      openStopAttendanceConfirmationModal(index) {
        this.stopIndex = index;
        this.$bvModal.show('stop-attendance-confirmation-modal');
      },

      stopAttendance() {
        this.loading = true;
        let endDate = new Date();
        endDate.setHours(endDate.getHours() - 3);

        httpClient.patch(`${process.env.VUE_APP_API_URL}order-attendances/${this.currentOrderAttendances[this.stopIndex].id}`, {
          endDate: endDate
        }).then(data => {
          this.loading = false;
          this.$notify.success('Apontamento finalizado com sucesso');
          this.currentOrderAttendances.splice(this.stopIndex, 1);
          this.updateIsAnyOfTheCurrentOrderAttendancesFromThisOrder();
        }).catch(error => {
          this.loading = false;
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError('Houve um erro ao finalizar o apontamento')
          }
        });
      },

      operationBadgeClassObject(orderAttendance) {
        return shared.operationBadgeClassObject({
          fiscalOperation: orderAttendance
        });
      },

      startAutomaticAttendance() {
        let authUser = shared.getLocalStorageObj('auth/user');
        let url = `${process.env.VUE_APP_API_URL}order-attendances/automatic/start/${this.type}`;
        let conditions = [
          {
            field: 'user.id',
            value: authUser.id
          },
          {
            field: 'automatic',
            value: true
          },
          {
              logicalOperator: 'OR',
              conditions: [
                {
                  field: 'type',
                  conditionalOperator: 'IS_NULL'
                },
                {
                  field: 'type',
                  conditionalOperator: 'NOT_EQUALS',
                  value: 'CONF'
                }
              ]
            }
        ];

        if (this.orderId) {
          url += `?orderId=${this.orderId}`;
          conditions.push({
            field: 'order.id',
            value: this.orderId
          });
        } else if (this.stockAdjustmentId) {
          url += `?stockAdjustmentId=${this.stockAdjustmentId}`;
          conditions.push({
            field: 'stockAdjustment.id',
            value: this.stockAdjustmentId
          });
        } else {
          return;
        }

        httpClient.post(`${process.env.VUE_APP_API_URL}order-attendances/condition?page=0&size=1`, { conditions })
        .then(({ data }) => {
          if (!data.data.content.length) {
            httpClient.post(url, {}).then(data => {
              this.$notify.success('Apontamento automático iniciado com sucesso');
            }).catch(error => {
              if (error.message) {
                this.$notify.error(error)
              } else {
                this.$notify.textError('Houve um erro ao gerar o apontamento automático')
              }
            });
          }
        }).catch(error => {
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError('Houve um erro ao consultar os apontamentos')
          }
        });
      }
    }
  }
</script>

<style scoped>

</style>
