<template>
  <div>
    <div v-if="loading">
      <animation />
    </div>
    <b-button v-else variant="dark" @click="exportToTxt">
      <i class="fa fa-download"></i> Exportar para txt
    </b-button>

    <b-modal
      size="sm"
      centered
      lazy
      title="Dados do arquivo exportado"
      header-bg-variant="info"
      footer-border-variant="info"
      v-model="showFieldSizeModal"
      :hide-footer="true"
    >
      <b-container>
        <span v-if="stockAdjustment.stockLocale">
          <span class="font-weight-bold">Armazém: </span> {{stockAdjustment.stockLocale.name}}
        </span><br>
        <span class="font-weight-bold">Posição Qtde: </span> {{largestGtin}}<br>
        <span class="font-weight-bold">No. Dígitos Qtde: </span> {{largestIntPart}}<br>
        <span class="font-weight-bold">Casas Decimais: </span> {{largestDecimalPart}}<br>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
  import Axios from 'axios';
  import shared from "@/shared/shared";
  import Animation from "@/components/loaders/animation";
  import { auth } from "@/service";

  export default {
    name: 'stock-adjustment-export-to-txt-button',
    components: {
      Animation
    },

    data() {
      return {
        loading: false,
        showFieldSizeModal: false,
        largestGtin: null,
        largestIntPart: null,
        largestDecimalPart: null
      }
    },

    props: {
      stockAdjustment: {
        type: Object,
        require
      }
    },

    methods: {
      exportToTxt() {
        this.loading = true;
        Axios({
          url: `${process.env.VUE_APP_API_URL}reports/stock-adjustment/${this.stockAdjustment.id}/item-quantity-per-warehouse`,
          method: 'GET',
          responseType: 'arraybuffer',
          headers: auth.getAxiosHeaders()
        }).then((response) => {
          console.log(response);
          this.loading = false;

          this.largestGtin = response.headers.largestgtin;
          this.largestIntPart = response.headers.largestintpart;
          this.largestDecimalPart = response.headers.largestdecimalpart;
          this.showFieldSizeModal = true;

          if (response && response.data) {
            let blob = new Blob([response.data], { type: 'text/plain' });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = response.headers.filename;
            link.click();
          } else {
            this.$notify.textError('Nenhum resultado encontrado');
          }
        }).catch((error) => {
          this.loading = false;

          if (error.response && error.response.headers && error.response.headers.errormessage) {
            this.$notify.textError(error.response.headers.errormessage);
          } else if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError('Ocorreu um erro inesperado');
          }
        });
      }
    }
  }
</script>
