<template>
  <div class="animated fadeIn">
    <confirmation-modal
      id="concurrent-attendance-modal"
      :msg="message"
      :cancel="goBack"
    ></confirmation-modal>
  </div>
</template>

<script>
import { httpClient } from '@/service';
import shared from '@/shared/shared';
import ConfirmationModal from '@/components/common/confirmation-modal';

export default {
  name: 'order-attendance-warning',
  components: { ConfirmationModal },

  data() {
    return {
      orderAttendace: null
    }
  },
  props: [ 'orderId', 'stockAdjustmentId' ],

  mounted() {
    this.getOrderAttendance();
  },

  computed: {
    message() {
      return this.orderAttendace ? (
        'AVISO: O usuário ' + this.orderAttendace.user.name + ' já está apontando este pedido. Tem certeza de que deseja continuar?'
      ) : '';
    }
  },

  methods: {
    getOrderAttendance() {
      let authUser = shared.getLocalStorageObj('auth/user');

      let conditions = [
        {
          field: 'user.id',
          conditionalOperator: 'NOT_EQUALS',
          value: authUser.id
        },
        {
          field: 'endDate',
          conditionalOperator: 'IS_NULL'
        }
      ];

      if (this.orderId) {
        conditions.push({
          field: 'order.id',
          value: this.orderId
        });
      } else {
        conditions.push({
          field: 'stockAdjustment.id',
          value: this.stockAdjustmentId
        });
      }

      httpClient.post(`${process.env.VUE_APP_API_URL}order-attendances/condition?page=0&size=1`, { conditions })
      .then(({ data }) => {
        if (data.data.content.length) {
          this.orderAttendace = data.data.content[0];
          this.$bvModal.show("concurrent-attendance-modal");
        }
      }).catch(error => {
        if (error.message) {
          this.$notify.error(error)
        } else {
          this.$notify.textError('Houve um erro ao consultar os apontamentos')
        }
      });
    },

    goBack() {
      this.$router.go(-1);
    }
  }
}
</script>
