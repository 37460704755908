<template>
  <b-modal :id="id" :title="title" size="md" centered lazy header-bg-variant="info" footer-border-variant="info" :hide-footer="true">
    <b-container>
      <div v-if="loadingOrderAttendances" class="mb-5 pb-5">
        <loading-animation />
      </div>

      <div v-if="!loadingOrderAttendances && (!orderAttendances || !orderAttendances.length)">
        <div class="row bg-light py-3">
          <div class="col text-center">
            Nenhum apontamento encontrado
          </div>
        </div>
      </div>

      <div v-if="!loadingOrderAttendances && orderAttendances && orderAttendances.length">
        <div v-for="(orderAttendance, index) in orderAttendances" :key="index" :class="{ 'bg-light': index % 2 == 0 }"
          class="row py-2 mx-0 border cursor-pointer">
          <div class="col-auto">
            <span class="font-weight-bold">Início: </span><br> {{orderAttendance.formattedStartDate}}
          </div>
          <div v-if="orderAttendance.endDate" class="col-auto">
            <span class="font-weight-bold">Fim: </span><br> {{orderAttendance.formattedEndDate}}
          </div>
          <div class="col-auto">
            <span class="font-weight-bold">Tempo: </span><br> {{orderAttendance.hoursAndMinutes}}
          </div>
          <div class="col-12">

            <div class="row">
              <div v-if="orderAttendance.type" class="col-auto">
                <span class="badge badge-secondary">{{getTypeDescription(orderAttendance.type)}}</span>
              </div>
              <div v-if="orderAttendance.automatic" class="col-auto">
                <span class="badge badge-warning">Automático</span>
              </div>

            </div>
          </div>
        </div>

        <nav class="mt-3">
          <b-pagination
            v-if="orderAttendancesTotalRows"
            align="center"
            :limit="5"
            :total-rows="orderAttendancesTotalRows"
            :per-page="orderAttendancesPerPage"
            prev-text="Anterior"
            next-text="Próximo"
            v-model="orderAttendancesCurrentPage"
            @change="orderAttendancesPageChanged"
            :disabled="loadingOrderAttendances"/>
        </nav>
      </div>
    </b-container>
  </b-modal>
</template>

<script>
  import { httpClient } from '@/service';
  import { LoadingAnimation } from '@/components/loaders';
  import shared from '@/shared/shared';

  export default {
    name: 'order-attendances-table-modal',
    components: { LoadingAnimation },

    props: {
      id: {
        type: String,
        require
      },
      title: {
        type: String,
        require
      },
      orderId: {
        type: String
      },
      stockAdjustmentId: {
        type: String
      }
    },

    data() {
      return {
        orderAttendances: [],
        loadingOrderAttendances: false,
        orderAttendancesCurrentPage: 1,
        orderAttendancesTotalRows: 0,
        orderAttendancesPerPage: 5
      }
    },

    methods: {
      orderAttendancesPageChanged(page) {
        this.orderAttendancesCurrentPage = page;
        this.getOrderAttendances();
      },

      getOrderAttendances() {
        let conditions = [];

        if (this.orderId) {
          conditions.push({
            field: 'order.id',
            value: this.orderId
          });
        } else if (this.stockAdjustmentId) {
          conditions.push({
            field: 'stockAdjustment.id',
            value: this.stockAdjustmentId
          });
        } else {
          return;
        }

        this.loadingOrderAttendances = true;
        this.orderAttendances = [];

        httpClient.post(`${process.env.VUE_APP_API_URL}order-attendances/condition?page=${this.orderAttendancesCurrentPage - 1}&size=${this.orderAttendancesPerPage}&sort=createdAt,desc`, {
          conditions: conditions
        }).then(data => {
          this.loadingOrderAttendances = false;
          this.orderAttendancesTotalRows = data.data.data.totalElements;
          this.orderAttendancesCurrentPage = data.data.data.number + 1;
          this.orderAttendances = data.data.data.content;

          this.orderAttendances.forEach(oa => {
            oa = shared.setAttendanceFields(oa, null, true);
          });
        }).catch(error => {
          this.loadingOrderAttendances = false;
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError('Houve um erro ao buscar os apontamentos do pedido')
          }
        });
      },

      getTypeDescription(type) {
        if (type) {
          switch (type) {
            case 'ALOC': return 'Alocação';
            case 'SEPA': return 'Separação';
            case 'AJUS': return 'Ajuste';
            case 'CONF': return 'Conferência';
          }
        }

        return type;
      }
    }
  }
</script>

<style scoped>
</style>
